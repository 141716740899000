<template src="./completeeft.html"></template>
<style scoped src="./completeeft.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import TransactionService from '@/services/TransactionService.vue'
import utils from '@/utils/utils.vue'
import modal from '@/components/modal/notification/modal'
import moment from 'moment'
export default {
    data () {
        return {
            externalReference : '',
            customerEmail : '',
            transactionId : '',
            resdata :{
                requestid:'',
                notes: [],
            },
           currentPage: 1,
           perPage : 8,
           xmlData: null,
           notes: '',
           loaderVisible :false,
           alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            fields: [
                {key: 'CreateDateUtc', label: 'Created Date'},
                {key: 'CreatedBy', label: 'Created by'},
                {key: 'Notes', label: 'Note' }
            ]
        }
    },
    components : {
        Loading, modal
    },
    methods : {
        async handleAddNotesEvent(){
            try {
                if(this.notes.trim().length > 0) {
                    this.loaderVisible = true
                    await TransactionService.methods.AddTransactionNotes(this.notes,this.resdata.referenceNumber)
                    .then( () => {
                        this.notes = ''
                        this.updateNotesTable()
                        this.loaderVisible = false
                        this.showAlert(1, 'Noted Added Successfully')
                    })
                    .catch( err => {
                        this.loaderVisible= false
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
                } else {
                    this.showAlert(null, 'Please Enter Notes')
                }
            } catch (error) {
                this.loaderVisible = false
            }
        },
        delay(n) {
            return new Promise(function(resolve){
                setTimeout(resolve,n*1000);
            });
        },
        async completeEFTTransaction(){
            try {
                this.loaderVisible = true
                await TransactionService.methods.CompleteEFTTransaction({
                    RequestId : utils.methods.GetRandomNumber,
                    ParentReferenceNumber : this.resdata.parentReferenceNumber,
                    ReferenceNumber : this.resdata.referenceNumber,
                    TransactionId : this.resdata.transactionNumber,
                    Amount : this.resdata.sourceAmount
                })
                .then(async res => {
                    if(res.csEFTCompleteTransactionDataResponse.SendMoneyRequest){
                        if(res.csEFTCompleteTransactionDataResponse.PromoCodeData?.Code !== undefined){
                            if(res.csEFTCompleteTransactionDataResponse.PromoCodeData.Code !== null && res.csEFTCompleteTransactionDataResponse.PromoCodeData.Issue !== null) {
                                await TransactionService.methods.RedeemPromoCode({
                                    CustomerEmail : this.customerEmail,
                                    Code : res.csEFTCompleteTransactionDataResponse.PromoCodeData.Code._text,
                                    IssueNumber : res.csEFTCompleteTransactionDataResponse.PromoCodeData.Issue._text,
                                    ValidateOnly : 'false',
                                    ParentReferenceNumber : this.resdata.referenceNumber,
                                    RemittanceGateway : res.csEFTCompleteTransactionDataResponse.PromoCodeData.RemittanceGateway._text ,
                                    Amount : res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.XferAmount._text
                                })
                                .then(() => {
                                    this.sendMoney(res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.OriginCurrency._text,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.RecipientId._text,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.XferAmount._text,this.customerEmail,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.SessionId._text,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.Purpose._text,this.resdata.referenceNumber,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.RequestedDestinationAmount._text)
                                })
                                .catch(err => {
                                    this.alertObject = err
                                    this.$bvModal.show(this.$refs.childModal.id)
                                })
                            }else{
                                 this.sendMoney(res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.OriginCurrency._text,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.RecipientId._text,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.XferAmount._text,this.customerEmail,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.SessionId._text,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.Purpose._text,this.resdata.referenceNumber,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.RequestedDestinationAmount._text)
                            }
                        }else {
                            this.sendMoney(res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.OriginCurrency._text,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.RecipientId._text,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.XferAmount._text,this.customerEmail,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.SessionId._text,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.Purpose._text,this.resdata.referenceNumber,res.csEFTCompleteTransactionDataResponse.SendMoneyRequest.RequestedDestinationAmount._text)
                        }
                    }else{
                        this.showAlert(null, 'Invalid Transaction Details. Not able to process E-Transfer Compliance.')
                    }
                    this.loaderVisible  = false
                })
                .catch(err => {
                    this.loaderVisible = false
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                await this.delay(1)
                this.goBack()
            } catch (error) {
                this.loaderVisible = false
            }
        },
        async sendMoney(originCurrency,recipientId,xferAmount,customerEmail,mrmssession,purpose,parentReferenceNumber,requestedDestinationAmount){
            if(this.isDisabledBank(customerEmail,recipientId)){
                this.showAlert(null, "Recipient's bank are temporarily disabled. The bank's system is under maintenance.")
                return
            }
            await TransactionService.methods.SendMoney({
                OriginCurrency : originCurrency,
                RecipientId : recipientId,
                XferAmount : xferAmount,
                CustomerEmail : customerEmail,
                Purpose : purpose,
                ParentReferenceNumber : parentReferenceNumber,
                SessionId : mrmssession,
                RequestedDestinationAmount : requestedDestinationAmount
            })
            .then(res => {
                this.$router.push({
                    name: 'Confirmation',
                    params: {
                        resSendMoney : res.csSendMoneyResponse.TransactionData,
                        parentReferenceNumber : res.csSendMoneyResponse.ParentReferenceNumber
                    }
                })
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
                this.loaderVisible = false
            })
        },
        isDisabledBank(customerEmail,recipientId){
            try {
                let recipientInfo = this.recipientList.find(el=> el.Id === recipientId)
                if(recipientInfo !== undefined){
                    if(recipientInfo.RemittanceGatewayId === '2'){
                        let customProperties = recipientInfo.CustomProperties
                        customProperties = this.responseMapping(customProperties)
                        let bankCode = customProperties.find(el=> el.Name === 'bankCode' )
                        if(bankCode !== undefined){
                            //check with disabled bank codes list if it contain return true
                        }
                    }
                }
                return false
            } catch (error) {
               //
            }
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        goBack () {
            this.$router.push({
                name : 'CompleteEFT',
                params: {
                    filters: this.$route.params.filters
                }
            })
        },
        async updateNotesTable() {
            await TransactionService.methods.GetTransactionDeatils({
                CustomerEmail : this.customerEmail,
                TransactionId : this.transactionId
            })
            .then(res => {
                this.resdata.notes = Object.keys(res.csTransactionDetailsResponse.TransactionNotes).length > 0 ? Array.isArray(res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote) ? (res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote).map(s => ({ CreateDateUtc: s.CreateDateUtc._text, CreatedBy: s.CreatedBy._text, Notes: s.Note._text })) : [{
                    CreateDateUtc : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreateDateUtc._text,
                    CreatedBy  : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreatedBy._text,
                    Notes  :  res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.Note._text
                }] : []
                this.resdata.notes.forEach(note => {
                    note.CreateDateUtc = utils.methods.UTCtoLocal(moment(note.CreateDateUtc).format('MM/DD/YYYY HH:mm:ss'))
                })
            })
        }
    },
    computed : {
      rows () {
            return this.resdata.notes?.length
       }
    },
    async created () {
        try {
            this.loaderVisible = true
            this.customerEmail = this.$route.params.email
            this.transactionId = this.$route.params.Transactionid   
            await TransactionService.methods.GetTransactionDeatils({
                CustomerEmail : this.customerEmail,
                TransactionId : this.transactionId
            })
            .then(res => {
                this.resdata.parentReferenceNumber = res.csTransactionDetailsResponse.TransactionData.ParentReferenceNumber._text
                this.resdata.requestid=res.csTransactionDetailsResponse.RequestId?._text
                this.resdata.customerEmail=res.csTransactionDetailsResponse.TransactionData.SenderEmail._text
                this.resdata.transactionNumber=res.csTransactionDetailsResponse.TransactionData.TransactionNumber._text
                this.resdata.referenceNumber=res.csTransactionDetailsResponse.TransactionData.ReferenceNumber._text
                this.resdata.dateAndTime= utils.methods.UTCtoLocal(moment(moment(res.csTransactionDetailsResponse.TransactionData.DateTime._text, 'MM/DD/YYYY HH:mm:ss')._d).format( 'MM-DD-YYYY HH:mm:ss'))
                this.resdata.transactionType=res.csTransactionDetailsResponse.TransactionData.TransactionType._text
                this.resdata.destinationAmount=res.csTransactionDetailsResponse.TransactionData.DestAmount._text
                this.resdata.requestedAmount=res.csTransactionDetailsResponse.TransactionData.RequestedAmount._text
                this.resdata.serviceCharge=res.csTransactionDetailsResponse.TransactionData.ServiceCharge._text
                this.resdata.sourceAmount=res.csTransactionDetailsResponse.TransactionData.SourceAmount._text
                this.resdata.phoneNumber=res.csTransactionDetailsResponse.TransactionData.PhoneNumber._text
                this.resdata.notes = Object.keys(res.csTransactionDetailsResponse.TransactionNotes).length > 0 ? Array.isArray(res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote) ? (res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote).map(s => ({ CreateDateUtc: s.CreateDateUtc._text, CreatedBy: s.CreatedBy._text, Notes: s.Note._text })) : [{
                    CreateDateUtc : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreateDateUtc._text,
                    CreatedBy  : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreatedBy._text,
                    Notes  :  res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.Note._text
                }] : []
                this.xmlData=res.csTransactionDetailsResponse.TransactionData.RemittanceData._text
                this.resdata.notes.forEach(note => {
                    note.CreateDateUtc = utils.methods.UTCtoLocal(moment(note.CreateDateUtc).format('MM/DD/YYYY HH:mm:ss'))
                })
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)  
                this.loaderVisible=false
            })
            let remittanceData=utils.methods.getParseObjectFromXml(this.xmlData)
            this.resdata.isCustomerReliable=remittanceData.MoneyTransferRequestSavedData.MrmsCallData.CustomerIsReliable._text.toLowerCase()
            // await AccountService.methods.GetAccountDetails(this.customerEmail)
            // .then(res => {
            //     this.customerNumber=res.csGetUserProfileResponse.UserRefId?._text
            // })
            // .catch(err => {
            //     this.alertObject = err
            //     this.$bvModal.show(this.$refs.childModal.id)
            //     this.loaderVisible=false
            // })
            this.loaderVisible=false
        } catch (error) {
            this.loaderVisible = false
        }
    },
}
</script>
